<template>
  <mi-dialog
    :model-value="modelValue"
    class="combinations-selection-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-lg"> Selection details </h5>

    <!-- Available actions -->
    <mi-list class="q-mb-md">
      <mi-list-item
        v-for="(action, index) in availableActionsWithCollection"
        :key="index"
        class="q-px-none"
      >
        <mi-list-item-section>
          <mi-list-item-label class="text-weight-bold text-body2"> {{ action.name }} </mi-list-item-label>
          <mi-list-item-label class="text-weight-regular" caption>
            {{ action.description }}
            <span v-if="action.isNameRequired" class="text-weight-bold"> (name is required) </span>
          </mi-list-item-label>
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>

    <!-- Save button -->
    <template #actions>
      <mi-btn @click="openSelectedElementsInSearchPage"> Open </mi-btn>
      <template v-if="$canAccess('CREATE_COLLECTIONS')">
      </template>
    </template>
  </mi-dialog>
</template>

<script>
  import { useStore } from 'vuex'

  import { postSummary } from '@/api'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { required } from '@/utils/validators'

  import { SEARCH_RESULT_TYPES } from '@/constants'
  import { CLEAR_SELECTED_ELEMENTS, UPDATE_SELECTED_ELEMENTS } from '@/store/modules/search/mutationTypes'
  import { VM_OPENED_COLLECTION, VM_SAVED_COLLECTION } from '@/utils/analytics/constants'
  import { computed, ref } from 'vue'
  import { useRouter } from 'vue-router'

  export default {
    name: 'CombinationsTableSelectionDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      selectedComponents: {
        type: Array,
        required: true
      },
      selectedOptions: {
        type: Array,
        required: true
      }
    },
    emits: ['update:model-value'],
    setup(props, { emit }) {
      const isCollectionSaving = ref(false)
      const collectionName = ref('')
      const validationRules = ref([required])
      const availableActionsWithCollection = ref([
        { name: 'Open', description: 'Visualize selected elements in the "Search" page', isNameRequired: false }
      ])

      const store = useStore()
      const selectedProductModel = computed(() => store.state['product-model'].selectedProductModel)

      const router = useRouter()

      const clearSelectedElements = elementsType => {
        store.commit(`search/${ CLEAR_SELECTED_ELEMENTS }`, elementsType)
      }

      const updateSelectedElements = ({ elementsType, subElementsType, elements }) => {
        store.commit(
          `search/${ UPDATE_SELECTED_ELEMENTS }`, { elementsType, subElementsType, elements }
        )
      }

      const emitUpdateModelValue = (value = false) => {
        emit('update:model-value', value)
      }

      const openSelectedElementsInSearchPage = () => {
        clearSelectedElements(SEARCH_RESULT_TYPES.OPTIONS)
        clearSelectedElements(SEARCH_RESULT_TYPES.COMPONENTS)

        updateSelectedElements({
          elementsType: SEARCH_RESULT_TYPES.OPTIONS,
          subElementsType: SEARCH_RESULT_TYPES.CHOICES,
          elements: props.selectedOptions
        })
        updateSelectedElements({
          elementsType: SEARCH_RESULT_TYPES.COMPONENTS,
          subElementsType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS,
          elements: props.selectedComponents
        })

        // Analytics
        recordAnalytics(VM_OPENED_COLLECTION)

        emitUpdateModelValue()

        router?.push({ name: 'search' })
      }

      const saveCollection = async (openSearchPage = false) => {
        const isCollectionNameValid = await collectionName.value?.validate?.()

        if (isCollectionNameValid) {
          isCollectionSaving.value = true

          try {
            await postSummary({
              name: collectionName.value,
              pmEncodedBusinessName: selectedProductModel.value.encodedBusinessName,
              options: props.selectedOptions,
              components: props.selectedComponents
            })

            if (openSearchPage) {
              openSelectedElementsInSearchPage()
            }
            else {
              emitUpdateModelValue()
            }

            notify({
              title: 'Saved',
              content: `Collection "${ collectionName.value }" was successfully saved`,
              type: 'positive'
            })

            // Analytics
            recordAnalytics(VM_SAVED_COLLECTION)
          }
          finally {
            isCollectionSaving.value = false
          }
        }
      }
      return {
        collectionName,
        isCollectionSaving,
        validationRules,
        availableActionsWithCollection,
        saveCollection,
        openSelectedElementsInSearchPage,
        emitUpdateModelValue
      }
    }
  }
</script>

<style lang="scss">
  .hidden-btns {
    display: none;
  }

  .combinations-selection-dialog .q-dialog__inner > .mi-card {
    width: 560px;
    max-width: 560px;
    min-width: 560px;
  }
</style>
