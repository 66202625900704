<template>
  <div class="saved-tables">
    <div class="saved-tables__header">
      <saved-tables-back-to-search
        @on-back-to-search-click="handleRedirectToSearchPage"
      >
      </saved-tables-back-to-search>
    </div>
    <div class="row saved-tables__filter-wrapper">
      <saved-tables-filter-bar
        :filters="filters"
        @on-handle-filters="onHandleFilters"
      >
      </saved-tables-filter-bar>
    </div>
    <div class="saved-tables__content">
      <div class="saved-tables__content-table">
        <saved-tables-list
          :rows="table.rows"
          :columns="table.columns"
          :loading="table.loading"
          @download-calculation-results="downloadCalculationResultsFile"
        ></saved-tables-list>
      </div>
      <div class="saved-tables__content-pagination">
        <mi-pagination
          v-if="!!table.rows.length"
          :model-value="pagination.currentPage"
          :max="pagination.totalPages"
          :max-pages="pagination.maxNavPage"
          size="14px"
          boundary-links
          direction-links
          boundary-numbers
          ellipses
          @update:model-value="handlePaginationChange"
        ></mi-pagination>
      </div>
    </div>
    <mi-dialog
      class="export-error-dialog"
      :model-value="showErrorModal"
      @update:model-value="toggleShowErrorModal"
    >
      <h3 class="q-mt-none q-mb-lg"> This file does not exist </h3>
      <p class="q-mb-xl">
        The splitted ID is only available for combinations created recently.
        Please, use export data default version to access its CSV file.
      </p>
      <template #actions>
        <mi-btn v-close-popup> OK</mi-btn>
      </template>
    </mi-dialog>
  </div>
</template>

<script>
  import { onMounted, watch, ref, provide, reactive } from 'vue'
  import SavedTablesBackToSearch from '@/components/saved-tables/SavedTablesBackToSearch.vue'
  import SavedTablesList from '@/components/saved-tables/SavedTablesList.vue'
  import SavedTablesFilterBar from '@/components/saved-tables/SavedTablesFilterBar.vue'
  import { usePagination } from '@/composables/usePagination'
  import { useTable } from '@/composables/useTable'
  import { savedTablesListColumns, buildExcelName, SAVED_TABLES_PROVIDER } from '@/components/saved-tables/utils'
  import { useRouter } from 'vue-router'
  import { SEARCH_ROUTE } from '@/router/routeNames'
  import {
    getAppliedVariantTreeCalculationResults,
    getAppliedVariantTreeCalculationFile,
    deleteVariantTreeCombination,
    updateVariantTreeCombinationName
  } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { SAVED_TABLES_OPENED, SAVED_TABLES_DOWNLOAD_CALCULATION } from '@/utils/analytics/constants'
  import { exportFile } from 'quasar'

  export default {
    name: 'SavedTablesContainer',
    components: { SavedTablesBackToSearch, SavedTablesList, SavedTablesFilterBar },
    setup() {
      const router = useRouter()
      const filters = reactive({ todaysFilter: false, searchField: '' })
      const { pagination, handleChange: handlePaginationChange } = usePagination()
      const { table, callbacks: { fetchTableData } } = useTable(savedTablesListColumns)
      const deleteCombinationInProgress = ref(false)
      const editCombinationNameInProgress = ref(false)
      const showErrorModal = ref(false)

      const handleRedirectToSearchPage = () => {
        router.push({ name: SEARCH_ROUTE.name })
      }

      const buildUrlSearchParams = () => {
        const params = new URLSearchParams()

        const { todaysFilter, searchField } = filters

        params.append('page', pagination.currentPage - 1)
        params.append('pageSize', pagination.pageSize)
        todaysFilter && params.append('date', `${ new Date().toISOString().substring(0, 10) }T00:00:00`)
        searchField && params.append('search', searchField)
        return params.toString()
      }

      const fetchCalculationResults = async () => {
        const params = buildUrlSearchParams()
        const fetchCalculationResultsPromise = () => getAppliedVariantTreeCalculationResults(params)
        const data = await fetchTableData(fetchCalculationResultsPromise)
        pagination.totalPages = data?.totalPages || 1
      }

      const onHandleFilters = (key, value) => {
        if (key) {
          filters[key] = value
          pagination.currentPage > 1 ? pagination.currentPage = 1 : fetchCalculationResults()
        }
        else {
          fetchCalculationResults()
        }
      }

      const downloadCalculationResultsFile = async prop => {
        try {
          const signedUrl = await getAppliedVariantTreeCalculationFile(
            prop.row?.correlationId,
            `splitCodeIdAndName=${ prop.split }`
          )
          const response = await fetch(signedUrl)
          const filename = buildExcelName(
            prop.row.name, prop.row.planningPeriod, prop.row.pmName, prop.split
          )

          exportFile(filename, await response.blob())
          recordAnalytics(SAVED_TABLES_DOWNLOAD_CALCULATION)
        }
        catch (err) {
          if (prop.split) {
            showErrorModal.value = true
          }
          else {
            throw new Error('Error downloading file:', err)
          }
        }
      }

      const deleteCombination = async correlationId => {
        deleteCombinationInProgress.value = true
        try {
          await deleteVariantTreeCombination(correlationId)
          fetchCalculationResults()
        }
        catch (err) {
          throw new Error(
            `An error occurred trying to delete the combination with the correlationId: ${ correlationId }`
          )
        }
        finally {
          deleteCombinationInProgress.value = false
        }
      }

      const editCombinationName = async (correlationId, name) => {
        editCombinationNameInProgress.value = true
        try {
          await updateVariantTreeCombinationName({ correlationId, name })
          fetchCalculationResults()
        }
        catch (err) {
          throw new Error(
            `An error occurred trying to edit the tables's name with the correlationId: ${ correlationId }`
          )
        }
        finally {
          editCombinationNameInProgress.value = false
        }
      }

      const toggleShowErrorModal = (value = false) => {
        showErrorModal.value = value
      }

      watch(() => pagination.currentPage, () => {
        fetchCalculationResults()
      })

      onMounted(async () => {
        recordAnalytics(SAVED_TABLES_OPENED)
        await fetchCalculationResults()
      })

      provide(SAVED_TABLES_PROVIDER, {
        deleteCombinationInProgress,
        editCombinationNameInProgress,
        deleteCombination,
        editCombinationName
      })

      return {
        handleRedirectToSearchPage,
        table,
        pagination,
        handlePaginationChange,
        buildUrlSearchParams,
        downloadCalculationResultsFile,
        fetchCalculationResults,
        filters,
        onHandleFilters,
        deleteCombination,
        deleteCombinationInProgress,
        editCombinationNameInProgress,
        editCombinationName,
        showErrorModal,
        toggleShowErrorModal
      }
    }
  }
</script>

<style lang="scss" scoped>
  .export-error-dialog {
    p {
      line-height: 24px;
    }
  }

  .saved-tables {
    max-width: 100vw;

    &__header {
      background: $mi-anthracite-50;
      height: 48px;
      display: flex;
      padding-left: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &__filter-wrapper {
      height: 3.75rem;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: .5rem 1rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .24);
      position: relative;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      gap: 24px;

      &-banner {
        max-width: 700px;
      }

      &-table {
        width: 100%;
      }

      &-pagination {
        display: flex;
        justify-content: center;
      }
    }
  }
</style>
