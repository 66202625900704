<template>
  <div class="home-page__wrapper">
    <div class="home-page column justify-between no-wrap full-height">
      <section class="home-page__intro row q-pb-sm">
        <div class="home-page__gradient"></div>
        <div class="home-page__text col-xl-6">
          <!-- Logo -->
          <img src="@/assets/images/man-logo.svg" alt="MAN logo" width="120" height="68" />

          <!-- Title -->
          <h1 class="home-page__title text-uppercase q-mb-lg"> Welcome to {{ appName }} </h1>

          <!-- Description -->
          <p class="home-page__description">
            We give immediate visual feedback on product configurations and easy rule management.
          </p>
        </div>
      </section>

      <section class="home-page__select col-auto">
        <h5 class="text-uppercase q-mt-none q-mb-sm"> Select one feature: </h5>
        <!-- Features -->
        <mi-list class="home-page__features-wrapper flex items-center no-wrap q-pt-lg q-pb-md">
          <mi-list-item
            v-for="({ title, icon, id }, routeName ) in ACTIVE_APP_FEATURES_ROUTES"
            :key="routeName"
            class="home-page__feature-item items-center bg-white q-px-lg"
            clickable
            @click="handleAppFeatureClick(routeName)"
          >
            <!-- Icon -->
            <mi-list-item-section class="items-center q-pa-none q-mr-md" avatar>
              <img :src="icon" alt="icon" width="50" height="50" />
            </mi-list-item-section>

            <!-- Label -->
            <mi-list-item-section
              class="text-weight-bold text-family-condensed text-no-wrap"
              :data-cy="`feature-${ id }`"
            >
              <div>
                {{ title }}
                <mi-icon
                  v-if="routeName === INSPECTOR_ROUTE.name"
                  name="info-circle-outline"
                  size="14px"
                  class="q-ml-sm"
                  @click.prevent="openInspectorDialogInfo"
                >
                </mi-icon>
              </div>
            </mi-list-item-section>
          </mi-list-item>
        </mi-list>
      </section>
      <inspector-explanation-dialog
        :model-value="isDialogVisible"
        :handle-close-dialog="() => isDialogVisible = false"
      ></inspector-explanation-dialog>
    </div>
  </div>
</template>

<script>
  import { ACTIVE_APP_FEATURES_ROUTES, INSPECTOR_ROUTE } from '@/router/routeNames'
  import getScreenChanged from '@/utils/analytics/utils/multi-naming'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import setRecordAnalyticsTrace, { clearRecordAnalyticsTrace } from '@/utils/analytics/recordAnalyticsTrace'
  import * as analyticsConstants from '@/utils/analytics/constants'
  import InspectorExplanationDialog from '@/components/inspector/InspectorExplanationDialog.vue'

  export default {
    name: 'Home',
    components: { InspectorExplanationDialog },
    data: () => ({
      ACTIVE_APP_FEATURES_ROUTES,
      appName: process.env.VUE_APP_NAME,
      isDialogVisible: false,
      truckImageElements: [
        { label: 'Solution Element', color: 'info', icon: 'solution-element-le', size: 21, left: '-70px', top: '20px' },
        { label: 'Validations', color: 'accent', icon: 'warning-circle', size: 78, left: '-20px', top: '80px' },
        { label: 'Option(s)', color: 'yellow', icon: 'options', size: 35, right: '20px', top: '200px' },
        { label: 'Choice(s)', color: 'yellow', icon: 'choices', size: 457, right: '-20px', top: '260px' },
        {
          label: 'Component Variant(s)',
          color: 'green',
          icon: 'component-variants',
          size: 42,
          left: '15px',
          bottom: '60px'
        }
      ],
      enableRoadmap: false
    }),
    computed: {
      INSPECTOR_ROUTE() {
        return INSPECTOR_ROUTE
      }
    },
    mounted() {
      // Analytics
      clearRecordAnalyticsTrace()
      recordAnalytics(analyticsConstants.HOME_PAGE_PI_PORTAL)
      setRecordAnalyticsTrace(analyticsConstants.HOME)
    },
    methods: {
      initializeAnalytics(routeName = '') {
        setRecordAnalyticsTrace(routeName)
        recordAnalytics(getScreenChanged(routeName))
      },
      handleAppFeatureClick(routeName = '') {
        this.initializeAnalytics(routeName)
        this.$router.push({ name: routeName })
      },
      handleRoadmapClick() {
        this.enableRoadmap = true
        setTimeout(() => {
          document.getElementById('roadmap').scrollIntoView()
        }, 0)
      },
      openInspectorDialogInfo(e) {
        e.stopPropagation()
        this.isDialogVisible = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .display-none {
    display: none;
  }

  .home-page {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;

    &__gradient {
      top: 0;
      position: absolute;
      background: linear-gradient(90deg, #1e2832 39%, rgba(30, 40, 50, 0) 89.26%);
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
      z-index: 0;
    }

    &__wrapper {
      height: 100%;
    }

    &__intro {
      position: relative;
      height: 70%;
      display: flex;
      align-items: center;
      background-image: url("../assets/images/home-page/home-background-img.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__select {
      height: 30%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding-left: 6%;
      position: relative;
    }

    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 72px;
      line-height: 82px;
      text-transform: uppercase;
      color: #ffffff;
    }

    &__text {
      z-index: 1;
      max-width: 730px;
      max-height: 371px;
      margin-left: 6%;
      margin-bottom: 12%;
      margin-top: 5%;
    }

    &__description {
      font-size: 1.5rem;
      line-height: 1.5;
    }

    &__truck-element-item {
      min-width: 250px;
      min-height: 3rem;
      border-color: $mi-anthracite-100;
      border-style: solid;
      border-width: 1px 1px 1px 0;
      box-shadow: 0 16px 32px rgba(0 0 0 / .24);
      z-index: 1;

      .absolute-bottom-left {
        top: -1px;
        bottom: -1px;
        width: 6px;
      }

      ::v-deep(.q-item__section--side) {
        font-size: .625rem;
        padding-top: 2px;
      }
    }

    &__features-wrapper {
      gap: 1.25rem;
    }

    &__feature-item {
      min-height: 98px;
      flex: 0 1 340px;
      font-size: 1.25rem;
      line-height: 1.2;
      border: 1px solid $mi-anthracite-50;
      box-shadow: 0 4px 8px rgba(0 0 0 / .24);
      width: 337px;
      height: 98px;

      ::v-deep(.q-item__section--avatar) {
        width: 50px;
        height: 50px;
        border-radius: 1rem;
      }
    }
  }

  @media (max-width: 1140px) {
    .home-page {
      &__title {
        font-size: 58px;
      }

      &__description {
        font-size: 1.2rem;
      }

      &__select {
        margin-top: 10%;
      }

      &__features-wrapper {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media (max-width: 780px) {
    .home-page {
      h1,
      p {
        width: 80%;
      }

      &__select {
        margin-top: 10%;
      }

      &__features-wrapper {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr;
      }
    }
  }
</style>
