<template>
  <div class="col-grow relative-position">
    <!-- Combinations table (tabulator) -->
    <div ref="combinationsTable" class="mi-tabulator compact"></div>

    <!-- Selection details dialog -->
    <selection-dialog
      v-model="isSelectionDialogShown"
      :selected-components="rowSelectedComponents"
      :selected-options="rowSelectedOptions"
    >
    </selection-dialog>
    <!-- Data loading spinner -->
    <mi-inner-loading class="bg-white" :showing="combinationsOptions.isCombinationsTableLoading">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> {{ combinationsOptions.combinationsTableLoadingContent }} </h6>
    </mi-inner-loading>

    <!-- No combinations found -->
    <div
      v-if="!(combinationsOptions.isCombinationsTableLoading || tableRows.length)"
      class="mi-tabulator__no-data-found-caption column flex-center bg-white absolute"
    >
      <mi-icon name="warning-circle" size="2rem"></mi-icon>
      <h6 class="q-mt-md q-mb-none"> No combinations found </h6>
    </div>
  </div>
</template>

  <script>
  import Tabulator from 'tabulator-tables'
  import { useStore } from 'vuex'

  import { getVariantTreeTableHeader, searchChoices, searchComponentVariant, swapVariantTreeTableColumns } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { base64EncodeUnicode } from '@/utils/base64'

  import { COMBINATIONS_STATES, PM_TYPE, SEARCH_RESULT_TYPES } from '@/constants'
  import { VARIANT_TREE_TABLE_CONTENT } from '@/api/rest/variant-tree/endpoints'
  import { VM_SWAP_COLUMN } from '@/utils/analytics/constants'

  import { computed, nextTick, onMounted, ref, watch } from 'vue'
  import SelectionDialog from './CombinationsTableSelectionDialog.vue'
  import { useCombinationsOptions } from '../utils/composables/useCombinationsOptions'

  const FROZEN_FIELD_NAME = 'rowNumber'

  const CSS_COL_CLASS = 'mi-tabulator__header-cell'
  const CSS_ROW_CLASS = 'mi-tabulator__header-cell--row-number'

  export default {
    name: 'CombinationsTable',
    components: { SelectionDialog },
    props: {
      correlationId: {
        type: String,
        required: true
      },
      showAmbiguous: {
        type: Boolean,
        required: true
      },
      onlyIncomplete: {
        type: Boolean,
        required: true
      }
    },
    setup(props) {
      const isSelectionDialogShown = ref(false)
      const tabulator = ref({})
      const tableHeader = ref([])
      const tableRows = ref([])
      const rowSelectedOptions = ref([])
      const rowSelectedComponents = ref([])
      const store = useStore()
      const combinationsTable = ref(null)

      const selectedSearchProductModel = computed(() => store.state['product-model'].selectedSearchProductModel)
      const resultsFilter = computed(() => store.state.search.resultsFilter)

      const { aliasesAsRequestParams, combinationsOptions } = useCombinationsOptions()

      const getChoices = async (query = '') => {
        const {
          encodedBusinessName,
          id,
          productModelType: pmType
        } = selectedSearchProductModel.value

        const pmIdentifier = pmType === PM_TYPE.PRODUCT_MODEL ? encodedBusinessName : id

        const { elements } = await searchChoices({ pmType, pmIdentifier, query })

        return elements
      }

      const getComponentVariants = async (query = '') => {
        const {
          encodedBusinessName,
          id,
          productModelType: pmType
        } = selectedSearchProductModel.value

        const pmIdentifier = pmType === PM_TYPE.PRODUCT_MODEL ? encodedBusinessName : id

        const { elements } = await searchComponentVariant({
          pmType, pmIdentifier, query
        })

        return elements
      }

      const isYellowWorld = (type, onlyHeader = false) => {
        const pluralType = `${ type }s`
        const { OPTIONS, CHOICES } = SEARCH_RESULT_TYPES
        return onlyHeader ? [OPTIONS].includes(pluralType) : [OPTIONS, CHOICES].includes(pluralType)
      }

      const isGreenWorld = (type, onlyHeader = false) => {
        const pluralType = `${ type }s`
        const { COMPONENTS, COMPONENT_VARIANTS } = SEARCH_RESULT_TYPES
        return onlyHeader ? [COMPONENTS].includes(pluralType) : [COMPONENTS, COMPONENT_VARIANTS].includes(pluralType)
      }

      const isSolutionElement = type => {
        const pluralType = `${ type }s`
        const { SOLUTION_ELEMENTS } = SEARCH_RESULT_TYPES
        return [SOLUTION_ELEMENTS].includes(pluralType)
      }

      const getHeaderColumnCssClass = (type = '') => {
        let cssClass = CSS_COL_CLASS

        if (isYellowWorld(type)) {
          cssClass += ` ${ CSS_COL_CLASS }--yellow`
        }
        else if (isGreenWorld(type)) {
          cssClass += ` ${ CSS_COL_CLASS }--green`
        }
        else if (isSolutionElement(type, true)) {
          cssClass += ` ${ CSS_COL_CLASS }--blue`
        }

        return cssClass
      }

      const updateRowSelectedOptionsAndComponents = async (rowCells = []) => {
        const optionTypeNameSingular = SEARCH_RESULT_TYPES.OPTIONS.slice(0, -1)
        const componentTypeNameSingular = SEARCH_RESULT_TYPES.COMPONENTS.slice(0, -1)
        const componentVariantsId2 = []
        const choicesId2 = []

        rowSelectedOptions.value = []
        rowSelectedComponents.value = []

        rowCells.forEach(({ codeId: subElementId2 } = {}, index) => {
          const { type: elementType } = tableHeader.value[index]

          if (elementType === optionTypeNameSingular) {
            choicesId2.push(base64EncodeUnicode(subElementId2))
          }
          else if (elementType === componentTypeNameSingular) {
            componentVariantsId2.push(base64EncodeUnicode(subElementId2))
          }
        })

        if (choicesId2.length) {
          const query = choicesId2.join(',')
          const choices = await getChoices(query)

          choices.forEach(choice => {
            rowSelectedOptions.value.push({
              id: choice.optionId,
              id2: choice.optionId2,
              name: choice.optionName,
              [SEARCH_RESULT_TYPES.CHOICES]: [choice]
            })
          })
        }

        if (componentVariantsId2.length) {
          const query = componentVariantsId2.join(',')
          const componentVariants = await getComponentVariants(query)

          componentVariants.forEach(componentVariant => {
            rowSelectedComponents.value.push({
              id: componentVariant.componentId,
              id2: componentVariant.componentId2,
              oid: componentVariant.componentOid,
              name: componentVariant.name,
              [SEARCH_RESULT_TYPES.COMPONENT_VARIANTS]: [componentVariant]
            })
          })
        }
      }

      const showSelectionDetailsDialog = async (event, row = {}) => {
        const tableRowIdx = row.getData().id - 1
        if (tableRowIdx <= -1) return

        const { cells } = tableRows.value[tableRowIdx]

        await updateRowSelectedOptionsAndComponents(cells)

        await nextTick(() => {
          isSelectionDialogShown.value = true
        })
      }

      const updateTableHeaderAfterColumnMoved = (columns = []) => {
        const newTableHeader = []
        let hasHeaderChanged = false

        columns
          .filter(({ _column } = {}) => _column.field !== FROZEN_FIELD_NAME)
          .forEach(({ _column } = {}, columnIdx) => {
            const headerItemIdx = tableHeader.value.findIndex(
              ({ field } = {}) => field === _column.field?.replace(/-/g, '.')
            )

            hasHeaderChanged = headerItemIdx !== columnIdx

            if (headerItemIdx > -1) {
              newTableHeader.push(tableHeader.value[headerItemIdx])
            }
          })

        return { newTableHeader, hasHeaderChanged }
      }

      const getCellLabel = ({ name = '', codeId = '', type = '' } = {}, showTitle = true) => {
        const { showCodeId, showName } = resultsFilter.value

        let title = ''

        if (isYellowWorld(type, true)) {
          title += '<p class="mi-tabulator-header-info-title">Choices for <p/>'
        }
        else if (isGreenWorld(type, true)) {
          title += '<p class="mi-tabulator-header-info-title">Component Variants for <p/>'
        }
        else if (isSolutionElement(type)) {
          title += '<p class="mi-tabulator-header-info-title">Solution elements for <p/>'
        }

        // eslint-disable-next-line max-len,vue/max-len
        return `${ showTitle ? title : '' } ${ showCodeId ? codeId : '' }${ showCodeId && showName ? ' - ' : '' }${ showName ? name : '' }`
      }

      const getHeaderColumns = () => {
        const col = [{
          formatter: 'rownum',
          field: FROZEN_FIELD_NAME,
          cssClass: `${ CSS_COL_CLASS } ${ CSS_ROW_CLASS }`,
          minWidth: 34,
          width: 34,
          resizable: false,
          headerSort: false,
          frozen: true
        }]

        const cols = tableHeader.value.map(({ name, codeId, field, type }) => ({
          title: getCellLabel({ name, codeId, type }),
          field: field.replace(/\./g, '-'),
          headerFilter: true,
          headerFilterPlaceholder: 'Search',
          cssClass: getHeaderColumnCssClass(type)
        }))

        return !tableRows.value.length ? [] : col.concat(cols)
      }

      const setTabulatorData = async () => {
        const { showCodeId, showName } = resultsFilter.value
        const endpointUrl = VARIANT_TREE_TABLE_CONTENT.replace(':correlationId', props.correlationId)
        const tableContentUrl = `${ process.env.VUE_APP_DOMAIN_URL }${ process.env.VUE_APP_VM_URL }${ endpointUrl }`
        const headers = {
          Authorization: `Bearer ${
            localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_TOKEN)?.replace(/^__q_strn\|/, '') || ''
          }`
        }

        combinationsOptions.isCombinationsTableLoading = true

        try {
          await tabulator.value.setData(
            tableContentUrl,
            {
              ...aliasesAsRequestParams(),
              showCodeId,
              showName,
              showAmbiguous: props.showAmbiguous,
              onlyIncomplete: props.onlyIncomplete
            },
            { headers }
          )

          tabulator.value.setColumns(getHeaderColumns())
          tabulator.value.setSort(combinationsOptions.appliedSorters)
          combinationsOptions.appliedFilters.forEach(({ field, value } = {}) => {
            tabulator.value.setHeaderFilterValue(field, value)
          })
        }
        finally {
          combinationsOptions.isCombinationsTableLoading = false
        }
      }

      const swapColumns = async (column, columns = []) => {
        combinationsOptions.combinationsTableLoadingContent = COMBINATIONS_STATES.REARRANGING

        const { newTableHeader, hasHeaderChanged } = updateTableHeaderAfterColumnMoved(columns)

        if (!hasHeaderChanged) return

        const { showCodeId, showName } = resultsFilter.value

        tableHeader.value = newTableHeader

        await swapVariantTreeTableColumns({
          showCodeId,
          showName,
          correlationId: props.correlationId,
          header: tableHeader.value
        })

        await setTabulatorData()

        // Analytics
        recordAnalytics(VM_SWAP_COLUMN)
      }

      const updateFilters = () => {
        if (!tabulator.value.getHeaderFilters) return

        const tabulatorFilters = tabulator.value.getHeaderFilters?.() || []
        combinationsOptions.appliedFilters = tabulatorFilters?.map(({ field, value } = {}) => ({ field, value }))
      }

      const updateSorters = (tabulatorSorters = []) => {
        if (!(combinationsOptions.appliedSorters.length || tabulatorSorters?.length)) return
        combinationsOptions.appliedSorters = tabulatorSorters?.map(
          ({ field, dir } = {}) => ({ column: field, dir })
        )
      }

      const formatResponseForTabulator = ({ lastPage, rows = [] } = {}) => {
        const startIndex = tabulator.value.getDataCount?.() || 0

        if (lastPage > 1) {
          tableRows.value.push(...rows)
        }
        else {
          tableRows.value = rows
        }

        const data = rows.map((row, index) => {
          const result = { id: startIndex + index + 1 }
          row?.cells.forEach((cell, idx) => {
            const { field } = tableHeader.value[idx]
            result[field.replace(/\./g, '-')] = getCellLabel(cell, false)
          })
          return result
        })

        return { last_page: lastPage, data }
      }

      const setTabulatorHeader = async () => {
        const { header } = await getVariantTreeTableHeader({
          aliases: aliasesAsRequestParams(),
          correlationId: props.correlationId
        })

        tableHeader.value = header
      }

      const initTabulator = async () => {
        combinationsOptions.combinationsTableLoadingContent = COMBINATIONS_STATES.LOADING

        tabulator.value = new Tabulator(combinationsTable.value, {
          ajaxFiltering: true,
          ajaxSorting: true,
          ajaxProgressiveLoad: 'scroll',
          columnMinWidth: 60,
          height: '100%',
          maxHeight: '100%',
          layout: 'fitColumns',
          movableColumns: true,
          tooltips: true,
          headerSortTristate: true,
          placeholder: '',
          paginationSize: 50,
          selectable: 'highlight',
          rowClick: showSelectionDetailsDialog,
          dataSorted: updateSorters,
          dataFiltered: updateFilters,
          columnMoved: swapColumns,
          ajaxResponse: (url, params, response) => formatResponseForTabulator(response)
        })

        await setTabulatorHeader()
        await setTabulatorData()
      }

      const refreshTabulator = async () => {
        combinationsOptions.appliedFilters = []
        combinationsOptions.appliedSorters = []

        await setTabulatorHeader()
        await setTabulatorData()
      }

      watch(
        () => combinationsOptions.appliedAliasesIds,
        () => {
          refreshTabulator()
        },
        { deep: true }
      )

      onMounted(() => {
        initTabulator()

        watch(
          () => [
            resultsFilter.value.showCodeId,
            resultsFilter.value.showName,
            props.showAmbiguous,
            props.onlyIncomplete
          ],
          () => setTabulatorData(),
          { deep: true }
        )
      })

      return {
        isSelectionDialogShown,
        rowSelectedComponents,
        rowSelectedOptions,
        combinationsOptions,
        tableRows,
        combinationsTable,
        tableHeader,
        tabulator,
        setTabulatorData,
        swapColumns,
        showSelectionDetailsDialog
      }
    }
  }
  </script>

  <style lang="scss">
    @import "@/styles/tabulator";

    p {
      margin: 0;
    }

    .mi-tabulator-header-info-title {
      font-family: "MAN Europe Condensed", sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-align: left;
    }
  </style>
