<template>
  <div class="inspector-table">
    <div v-if="table.rows">
      <mi-table
        ref="miTable"
        :columns="table.columns"
        :rows="table.rows"
        :loading="table.loading"
        class="q-px-lg q-pb-lg"
        column-sort-order="da"
        loading-label="Loading results. Please wait ..."
        row-key="id"
        hide-pagination
        sticky-header
        striped
        wrap-cells
      >
        <template #top-left>
          <div class="inspector-table__header">
            <div>
              <div class="row items-center">
                <p class="q-table__title q-mb-sm">Inspector</p>
                <mi-icon
                  name="info-circle-outline"
                  size="14px"
                  class="q-ml-sm cursor-pointer"
                  @click="() => isDialogVisible = true"
                >
                </mi-icon>
                <inspector-explanation-dialog
                  :model-value="isDialogVisible"
                  :handle-close-dialog="() => isDialogVisible = false"
                ></inspector-explanation-dialog>
              </div>
              <span class="inspector-table__header-counter q-mb-sm">
                {{ pagination.totalElements }} results found
              </span>
            </div>
          </div>
        </template>
        <template #top-right>
          <div class="inspector-table__header">
            <mi-btn
              color="accent"
              icon="plus"
              icon-size="12px"
            >
              New Inspection
            </mi-btn>
          </div>
        </template>
        <template v-if="!table.loading" #no-data>
          <div class="no-data-message">
            <p class="q-mb-sm">No result found</p>
            <p class="no-data-message__text">
              Press “New inspection” to start a process or review any filter applied.
            </p>
          </div>
        </template>
      </mi-table>
    </div>
    <div class="inspector-table__pagination">
      <mi-pagination
        :model-value="pagination.currentPage"
        :max="pagination.totalPages"
        :max-pages="pagination.maxNavPage"
        size="14px"
        boundary-links
        direction-links
        boundary-numbers
        ellipses
        @update:model-value="handlePaginationChange"
      ></mi-pagination>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import { INSPECTOR_TABLE_COLUMNS } from '@/constants/inspector'
  import { useTable } from '@/composables/useTable'
  import { usePagination } from '@/composables/usePagination'
  import MiTable from '@/packages/@mi-library/MiTable/MiTable.vue'
  import MiPagination from '@/packages/@mi-library/MiPagination/MiPagination.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import MiIcon from '@/packages/@mi-library/MiIcon/MiIcon.vue'
  import InspectorExplanationDialog from './InspectorExplanationDialog.vue'

  export default {
    name: 'InspectorTable',
    components: { MiIcon, MiBtn, MiPagination, MiTable, InspectorExplanationDialog },
    props: {},
    setup() {
      const { pagination, handleChange: handlePaginationChange } = usePagination({ pageSize: 20 })
      const { table, callbacks: tableCallbacks } = useTable(INSPECTOR_TABLE_COLUMNS)
      const isDialogVisible = ref(false)

      return {
        pagination,
        table,
        tableCallbacks,
        handlePaginationChange,
        isDialogVisible
      }
    }
  }
</script>

<style lang="scss" scoped>
  .inspector-table {
    min-height: calc(100vh - 60px);
    padding: 24px;

    .q-table__container {
      min-height: calc(100vh - 175px);
    }

    .q-table__title {
      font-family: $mi-typography-font-family-condensed;
    }

    &__pagination {
      display: flex;
      justify-content: center;
      padding: 8px 0 16px;
      background-color: $mi-white;
    }

    &__header-counter {
      color: #5b6f85;
      font-size: 12px;
      font-style: normal;
      font-family: $mi-typography-font-family-condensed;
      font-weight: 700;
      line-height: 130%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .mi-table-wrapper .q-table__bottom--nodata {
    align-items: center;

    .no-data-message {
      font-size: 14px;
      font-family: $mi-typography-font-family;
      font-weight: 700;
      color: $mi-anthracite-800;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        font-weight: 400;
      }
    }
  }
</style>
