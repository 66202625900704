<template>
  <div class="inspector-container">
    <inspector-table></inspector-table>
  </div>
</template>

<script>
  import InspectorTable from './InspectorTable.vue'

  export default {
    name: 'InspectorContainer',
    components: { InspectorTable },
    props: {},
    setup() {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
  .inspector-container {
    background-color: $mi-anthracite-50;
  }
</style>
