import { removePrefixFromPP, sortDate } from '../components/saved-tables/utils'
import formatDate from '../utils/formatDate'

export const INSPECTOR_TABLE_COLUMNS = [
  {
    name: 'name',
    label: 'Name',
    align: 'left',
    field: 'name',
    style: 'min-width:200px; max-width: 360px; word-break: break-word; capitalize',
    showSortElement: false,
    sortDirection: 'ASC',
    sortable: true
  },
  {
    name: 'elements',
    label: 'Elements',
    align: 'left',
    field: 'elements',
    style: 'min-width:160px;',
    sortable: false
  },
  {
    name: 'pmName',
    label: 'Product model',
    align: 'left',
    field: 'pmName',
    style: 'min-width: 160px; capitalize',
    sortable: false
  },
  {
    name: 'planningPeriod',
    align: 'left',
    label: 'Planning period',
    field: ({ planningPeriod } = {}) => removePrefixFromPP(planningPeriod),
    sortable: true,
    style: 'width: 20%; text-transform: capitalize'
  },
  {
    name: 'creator',
    label: 'Creator',
    align: 'left',
    field: 'creator',
    style: 'min-width: 160px;',
    sortable: false
  },
  {
    name: 'department',
    label: 'Dept.',
    align: 'left',
    field: 'department',
    style: 'min-width: 160px;',
    sortable: false
  },
  {
    name: 'status',
    label: 'Status',
    align: 'left',
    field: 'status',
    style: 'min-width: 160px;',
    sortable: false
  },
  {
    name: 'createdDate',
    label: 'Date (start - end)',
    align: 'left',
    sortable: true,
    field: ({ createdDate, calculationCompleteDate } = {}) => ({ createdDate, calculationCompleteDate }),
    sort: (a, b) => sortDate(a, b),
    format: ({ createdDate, calculationCompleteDate } = {}) => `
    ${ formatDate(createdDate) } - ${ calculationCompleteDate ? formatDate(calculationCompleteDate) : '' }`,
    style: 'width: 30%'
  }
]
