<template>
  <mi-tab-panel :name="name" class="q-px-none validations-scroll">
    <!-- Titles -->
    <template v-if="formSettings.length">
      <!-- Setting items -->
      <div
        v-for="(settingItem, index) in formSettings"
        :key="index"
        class="validation-settings-item row items-start q-gutter-x-lg"
      >
        <!-- Name -->
        <div class="col-3 validation-row-center" :class="isBOMSettings ? 'col-3' : 'col'">
          <mi-text-field
            v-model.trim="settingItem.name"
            :disable="!shouldDisplay"
            :name="name"
            :rules="nameRules"
            placeholder="Name"
            :label="index === 0 ? 'Validation name' : ''"
            outlined
          ></mi-text-field>
        </div>

        <!-- Planning period range -->
        <div class="q-ml-none" :class="isBOMSettings ? 'col-3' : 'col'">
          <planning-period-range
            v-model:from="settingItem.checkRange.from"
            v-model:to="settingItem.checkRange.from"
            :field-name="name"
            :disable="!shouldDisplay"
            :disable-to="true"
            :label-from="index === 0 ? 'Planning period' : ''"
            label-to=""
            :hide="isBOMSettings ? true : false"
          ></planning-period-range>
        </div>
        <mi-select
          v-if="isBOMSettings"
          ref="chips"
          :model-value="settingItem.preCondition || []"
          outlined
          use-input
          :error="settingItem.invalid"
          :error-message="settingItem.message"
          :placeholder="settingItem.preCondition?.length > 0 ? '' : 'eg.: AA001;BB001;BB002;'"
          multiple
          hide-dropdown-icon
          :label="index === 0 ? 'Precondition (optional)' : ''"
          class="col q-ml-sm"
          @new-value="(val, done) => createValue(val, settingItem, index, done)"
          @input-value="(val) => onChange(val, index)"
        >
          <template #selected-item="scope">
            <q-chip
              removable
              dense
              :tabindex="scope.tabindex"
              color="white"
              text-color="secondary"
              class="q-mr-md"
              :class="invalidChoices.has(scope.opt) ? 'q-chip-error' : 'q-chip-success'"
              @remove="removeValue(scope, settingItem, index)"
            >
              {{ scope.opt }}
            </q-chip>
          </template>
          <template #append>
            <product-model-settings-tooltip></product-model-settings-tooltip>
          </template>
        </mi-select>

        <!-- Remove button -->
        <mi-btn
          v-show="shouldDisplay"
          class="q-ml-xs q-mt-xs q-pa-none validation-row-center"
          color="negative"
          icon="minus-circle"
          icon-type
          fab
          flat
          @click="$emit('click:remove', index)"
        ></mi-btn>
      </div>
    </template>

    <div v-else class="text-center q-py-sm"> No planning periods specified </div>
  </mi-tab-panel>
</template>

<script>
  import { required } from '@/utils/validators'
  import { ref, nextTick, computed, watch } from 'vue'

  import PlanningPeriodRange from '@/components/base/PlanningPeriodRange.vue'
  import { containsSpecialChars } from '@/utils/validators/common'
  import useFeatureToggle from '@/composables/featureToggle'
  import { FEATURES } from '@/utils/featureToggle'
  import ProductModelSettingsTooltip from './ProductModelSettingsTooltip.vue'

  export default {
    name: 'ProductModelSettingsTab',
    components: { PlanningPeriodRange, ProductModelSettingsTooltip },
    props: {
      name: {
        type: [String, Number],
        required: true
      },
      settings: {
        type: Array,
        required: false,
        default: () => []
      },
      addPrecondition: {
        type: Function,
        required: false,
        default: () => {}
      },
      removePrecondition: {
        type: Function,
        required: false,
        default: () => {}
      },
      invalidChoices: {
        type: Set,
        required: false,
        default: () => new Set()
      }
    },
    emits: ['click:remove'],
    setup(props) {
      const validationSettingsLimit = ref(10)
      const nameRules = ref([required])
      const notUsableChoices = ref('notUsableChoices')
      const notUsableChoicesPrivileges = ref('CREATE_NOT_USABLE_CHOICES_SETTINGS')
      const bomPrivileges = ref('CREATE_VALIDATION_SETTINGS')
      const shouldDisplay = ref(true)
      const preconditionList = ref([])
      const formSettings = ref(props.settings)
      const { isEnabled } = useFeatureToggle(
        FEATURES.VUE_APP_PRECONDITION_TOGGLE
      )

      const isComponentLimitReached = (preconditions, values) => preconditions.length
        + values.length > 20

      const isBOMSettings = computed(() => props.name === 'bom' && isEnabled.value)

      const validateSpecialChars = ({ selectedValue = '', settingIndex }) => {
        const hasSpecialChar = containsSpecialChars(selectedValue)

        if (hasSpecialChar) {
          formSettings.value[settingIndex].message = 'The separator is not allowed'
          return true
        }

        return false
      }

      const validateMaxChoicesLimitReached = ({ preconditions = [], values = [], settingIndex }) => {
        const isLimitReached = isComponentLimitReached(preconditions, values)

        if (isLimitReached) {
          formSettings.value[settingIndex].message = 'The maximum number of choices is 20'
          return true
        }
        return false
      }

      const validateInvalidChoices = () => {
        formSettings.value.map(setting => {
          const hasMatch = setting.preCondition?.some(item => props.invalidChoices.has(item))
          if (hasMatch) {
            setting.invalid = true
            setting.message = 'Non existing code(s) in product model. Enter only valid codes.'
          }
          return setting
        })
      }

      const removeValue = (val, setting, settingIndex) => {
        const maxLimitReached = validateMaxChoicesLimitReached({ preconditions: setting.preCondition, settingIndex })
        formSettings.value[settingIndex].invalid = maxLimitReached

        props.removePrecondition(val, settingIndex)
      }

      const createValue = (val, setting, settingIndex, done) => {
        const preconditions = setting?.preCondition || []
        const valueList = val.toUpperCase().split(';').filter(item => !!item)

        const invalidChars = validateSpecialChars({ selectedValue: val, settingIndex })
        const maxLimitReached = validateMaxChoicesLimitReached({ preconditions, values: valueList, settingIndex })

        formSettings.value[settingIndex].invalid = invalidChars || maxLimitReached

        if (formSettings.value[settingIndex].invalid) return

        valueList.map(v => v.trim())
          .filter(v => v.length > 0 && v.length < 6)
          .forEach(v => preconditions.push(v))

        const updatedConditions = [...new Set(preconditions)]

        nextTick(() => {
          done?.([...updatedConditions].pop())
        })

        props.addPrecondition(updatedConditions, settingIndex)
      }

      const onChange = (value, settingIndex) => {
        if (!value) formSettings.value[settingIndex].invalid = false
      }

      watch([formSettings.value, () => props.invalidChoices], () => {
        validateInvalidChoices()
      })

      return {
        validationSettingsLimit,
        nameRules,
        notUsableChoices,
        notUsableChoicesPrivileges,
        bomPrivileges,
        shouldDisplay,
        createValue,
        preconditionList,
        removeValue,
        isBOMSettings,
        formSettings,
        onChange
      }
    }
  }
</script>

<style lang="scss" scoped>
  .validations-scroll {
    max-height: 300px;
    min-height: 120px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .validation-settings-item {
    margin-top: 16px;

    .validation-row-center {
      margin-top: 0;
    }

    ::v-deep(.mi-btn) {
      opacity: 0;
      pointer-events: none;
      font-size: .85rem;
    }

    &:hover ::v-deep(.mi-btn) {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .planning-period-range {
    gap: 8px;
    margin-top: 0;
    margin-left: 8px;
  }

  ::v-deep(.q-field) {
    // stylelint-disable declaration-no-important
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ::v-deep(.q-field__append) {
    height: 0 !important;
    color: $mi-anthracite-800;
    cursor: pointer;
  }

  .header {
    padding-bottom: 0;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .2px;
    color: $mi-anthracite-800;
    font-family: $mi-typography-font-family-condensed;

    &__vn {
      padding-left: 0;
    }

    &__pp {
      margin-left: 0;
      width: 212px;
    }

    &__preconditions {
      margin-left: 0;
    }
  }

  ::v-deep(.q-field__bottom) {
    padding: 2px !important;
  }

  ::v-deep(.q-field__bottom--stale) {
    transform: translateY(100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ::v-deep(.q-chip--dense .q-chip__icon) {
    font-size: 9px;
    padding: 2px;
    background-color: $mi-anthracite-800;
    border-radius: 50%;
    color: $mi-white;
    margin-left: 4px;
  }

  ::v-deep(.q-chip--dense .q-chip__icon--remove) {
    background-color: $mi-anthracite-800;
    opacity: 1;
  }

  ::v-deep(.q-chip) {
    margin-right: 0;
  }

  ::v-deep(.q-chip__content) {
    font-size: 12px;
    font-weight: 800;
    color: $mi-anthracite-800;
  }

  .q-chip-success {
    border: 2px solid $mi-anthracite-800 !important;
  }

  .q-chip-error {
    border: 2px solid $mi-red-700 !important;
    background-color: $mi-red-50 !important;

    ::v-deep(.q-chip__content) {
      color: $mi-red-700;
    }

    ::v-deep(.q-icon) {
      background-color: $mi-red-700;
    }
  }
</style>
