import { INSPECTOR_ROUTE } from '@/router/routeNames'

const Inspector = () => import(
  /* webpackChunkName: "inspector" */ '@/views/Inspector.vue'
)

const routes = [
  {
    path: INSPECTOR_ROUTE.path,
    name: INSPECTOR_ROUTE.name,
    component: Inspector,
    meta: {
      appFeatureSubRoute: INSPECTOR_ROUTE,
      title: INSPECTOR_ROUTE.title,
      isAppHeaderVisible: true
    }
  }
]

export default routes
