<template>
  <mi-drawer
    class="flex column justify-between"
    :model-value="true"
    :mini="isMiniState"
    width="255"
    mini-width="70"
    bordered
    overlay
    persistent
  >
    <mi-list separator>
      <!-- Header -->
      <mi-list-item class="items-center q-pl-lg q-py-md">
        <mi-list-item-section class="text-h6 text-weight-bold q-pl-xs" :class="{ 'hidden': isMiniState }">
          Results
        </mi-list-item-section>

        <!-- Toggle mini variant button -->
        <mi-btn
          :ripple="false"
          icon="arrows-right"
          icon-type
          icon-size="12px"
          class="collapse-icon q-mx-auto"
          :class="{ 'rotate-180': !isMiniState }"
          dense
          fab
          flat
          @click="isMiniState = !isMiniState"
        ></mi-btn>
      </mi-list-item>

      <!-- Result types -->
      <mi-list-item
        v-for="(resultTypeValue, resultTypeKey) in resultTypes"
        :key="resultTypeKey"
        data-cy="result-types"
        :to="{ query: { ...$route.query, resultType: resultTypeKey } }"
        :class="{
          'collection-item--active': selectedResultType === resultTypeKey,
          [`collection-item--${ resultTypeValue.color }`]: true
        }"
        class="collection-item text-weight-bold q-pl-lg"
        @click="UPDATE_SELECTED_RESULT_TYPE(resultTypeKey)"
      >
        <div class="collection-item__left-border absolute-bottom-left"></div>

        <!-- Icon -->
        <mi-list-item-section class="q-pl-xs" avatar>
          <mi-icon size="18px" :name="resultTypeValue.icon"></mi-icon>
        </mi-list-item-section>

        <!-- Label -->
        <mi-list-item-section :class="{ 'hidden': isMiniState }">
          {{ resultTypeValue.label }}
        </mi-list-item-section>

        <!-- Items amount -->
        <mi-list-item-section class="collection-item__items-amount" :class="{ 'hidden': isMiniState }" side>
          {{ resultTypeValue.totalSize }}
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>
    <div
      v-if="!isMiniState"
      class="saved-table__wrapper"
    >
      <mi-separator></mi-separator>
      <div
        class="saved-table"
        role="button"
        tabindex="0"
        @click="redirectToSavedTablePage"
      >
        <img
          class="saved-table__img"
          src="@/assets/images/my_combinations_results.svg"
          alt="my combination results icon"
        />
        <span
          class="saved-table-tooltip text-weight-bold text-primary ellipsis text-family-condensed"
        >
          Saved tables
        </span>
        <!-- show tooltip -->
        <man-tooltip
          v-if="showTooltip"
          id="saved-table-tooltip-id"
          :show="showTooltip"
          target-selector=".saved-table-tooltip"
          content="“Show me later” is activated.
          The table will run in the background for you to keep working."
          position="top"
        ></man-tooltip>
        <span v-if="areResultsLaterRequestProcessing">
          <man-tag variant="default" class="ellipsis">
            <div class="flex no-wrap">
              <man-spinner variant="standard" size="s" class="q-mr-sm"></man-spinner>
              <span>in progress</span>
            </div>
          </man-tag>
        </span>
      </div>
    </div>
  </mi-drawer>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { SAVED_TABLES_ROUTE } from '@/router/routeNames'
  import { SEARCH_ELEMENT_TYPES } from '@/constants'
  import { UPDATE_SELECTED_RESULT_TYPE } from '@/store/modules/search/mutationTypes'
  import { VM_SIDEBAR_COLLAPSED } from '@/utils/analytics/constants'
  import MiSeparator from '@/packages/@mi-library/MiSeparator/MiSeparator.vue'
  import * as analyticsConstants from '@/utils/analytics/constants'

  const { mapMutations, mapState } = createNamespacedHelpers('search')

  export default {
    name: 'SearchSideBar',
    components: { MiSeparator },
    data: () => ({
      isMiniState: false,
      showResultsLaterToggle: false,
      showTooltip: false
    }),
    computed: {
      ...mapState(['elements', 'selectedResultType', 'showResultsLater', 'areCombinationsLoading']),
      areResultsLaterRequestProcessing() {
        return this.showResultsLater && this.areCombinationsLoading
      },
      resultTypes() {
        return this.elements[SEARCH_ELEMENT_TYPES.STRUCTURE] || {}
      }
    },
    watch: {
      isMiniState(current) {
        // Analytics
        !current && recordAnalytics(VM_SIDEBAR_COLLAPSED)
      },
      showResultsLater(current) {
        if (current) {
          this.showTooltip = true
          setTimeout(() => {
            this.showTooltip = false
          }, parseInt(process.env.VUE_APP_NOTIFICATION_TIMEOUT_MS, 10))
        }
        else {
          this.showTooltip = false
        }
      }
    },
    methods: {
      ...mapMutations({ UPDATE_SELECTED_RESULT_TYPE }),
      redirectToSavedTablePage() {
        this.$router.push({ name: SAVED_TABLES_ROUTE.name })
        recordAnalytics(analyticsConstants.SAVED_TABLES_OPENED_FROM_SEARCH)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $collection-item-colors: ("yellow": $mi-yellow-400, "green": $mi-green-500);

  ::v-deep(.q-drawer) {
    // stylelint-disable declaration-no-important
    position: relative;
    top: 0 !important;
    height: 100%;
    z-index: initial;
    // stylelint-enable declaration-no-important
  }

  .collapse-icon {
    width: 28px;
  }

  .collection-item {
    min-height: 60px;
    color: $mi-silver-700;
    transition: none;

    ::v-deep(.q-item__section--avatar) {
      min-width: auto;
      padding-right: 10px;
    }

    &:last-child {
      border-bottom: 1px solid $mi-silver-200;
    }

    &__left-border {
      width: 8px;
      top: -1px;
      z-index: 1;
    }

    &:last-child .collection-item__left-border {
      bottom: -1px;
    }

    &__items-amount {
      color: $mi-black;
      font-size: .625rem;
      padding-left: .2rem;
    }

    @each $color-name, $color-value in $collection-item-colors {
      &--#{$color-name} &__left-border {
        background-color: $color-value;
      }

      &--active.collection-item--#{$color-name} {
        background-color: $color-value;
        border-color: $color-value;
      }
    }
  }

  .saved-table {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 16px 16px 32px;
    cursor: pointer;
    max-width: 260px;

    &__img {
      width: 24px;
      height: 24px;
    }

    &__header {
      margin: 0;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }
</style>
