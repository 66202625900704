<template>
  <div class="tooltip-container">
    <mi-icon
      ref="icon"
      name="info-circle"
      size="16px"
      @mouseenter="handleMouseEnter"
      @focus="handleMouseEnter"
      @blur="showTooltip = false"
      @mouseleave="showTooltip = false"
    >
    </mi-icon>
    <div
      v-if="showTooltip"
      class="custom-tooltip"
      :style="tooltipStyle"
    >
      <span class="text-body2 text-weight-bold text-primary text-family-condensed q-mb-sm">
        A precondition is a combination of choices
      </span>
      <span class="text-weight-regular text-body2 text-primary q-mb-sm">
        Enter all choices that need to be combined.
      </span>
      <span class="text-weight-regular text-body2 text-primary">
        The expression will be adjusted by adding operators AND | OR for choices from same or different options.
      </span>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'

  const TOOLTIP_LEFT_POSITION = 320
  const TOOLTIP_BOTTOM_POSITION = 16

  export default {
    name: 'ProductModelSettingsTooltip',
    setup() {
      const showTooltip = ref(false)
      const tooltipStyle = ref({})
      const icon = ref(null)

      const handleMouseEnter = () => {
        showTooltip.value = true

        const iconElement = icon.value?.$el || icon.value
        if (iconElement) {
          const rect = iconElement.getBoundingClientRect()
          tooltipStyle.value = {
            top: `${ rect.bottom + TOOLTIP_BOTTOM_POSITION }px`,
            left: `${ rect.left - TOOLTIP_LEFT_POSITION }px`,
            position: 'fixed'
          }
        }
      }

      return {
        showTooltip,
        tooltipStyle,
        icon,
        handleMouseEnter
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tooltip-container {
    position: relative;
    display: inline-block;
  }

  .custom-tooltip {
    display: flex;
    flex-direction: column;
    background-color: $mi-white;
    padding: 16px;
    border-radius: 4px;
    font-size: 12px;
    width: 352px;
    z-index: 1;
    box-shadow: 0 5px 4px 0 #00000040;
  }
</style>
