<template>
  <mi-dialog
    :close-icon="false"
    :model-value="true"
    class="combinations-dialog"
    full-width
    full-height
    no-esc-dismiss
    no-backdrop-dismiss
    seamless
  >
    <!-- Header -->
    <combinations-header
      v-model:show-ambiguous="showAmbiguous"
      v-model:only-incomplete="onlyIncomplete"
      :correlation-id="correlationId"
      :is-exporting="isTableDataExporting"
      :search-string="querySearchString"
      @click:auto-swap="autoSwapColumns"
      @click:export-data="exportTableData"
    >
      <mi-resize-observer @resize="handleCombinationsHeaderResize"></mi-resize-observer>
    </combinations-header>

    <!-- Table -->
    <combinations-table
      ref="combinationsTable"
      :correlation-id="correlationId"
      :show-ambiguous="showAmbiguous"
      :only-incomplete="onlyIncomplete"
      class="combinations-dialog__table"
    ></combinations-table>
  </mi-dialog>
</template>

<script>
  import { useStore } from 'vuex'
  import { exportFile } from 'quasar'

  import { exportVariantTreeTable } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { VM_AUTO_SWAP_COLUMN, VM_COMB_TABLE_EXPORT } from '@/utils/analytics/constants'

  import { buildExcelName } from '@/components/saved-tables/utils'
  import { computed, onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import CombinationsHeader from './combinations-header/CombinationsHeader.vue'
  import CombinationsTable from './combinations-table/CombinationsTable.vue'
  import { useCombinationsOptions } from './utils/composables/useCombinationsOptions'

  export default {
    name: 'Combinations',
    components: {
      CombinationsTable,
      CombinationsHeader
    },
    props: {
      correlationId: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const querySearchString = ref('')
      const route = useRoute()

      const store = useStore()
      const resultsFilterExcel = computed(() => store.state.search.resultsFilterExcel)
      const {
        combinationsOptions,
        filtersAsRequestParams,
        sortersAsRequestParams,
        aliasesAsRequestParams
      } = useCombinationsOptions()

      const isTableDataExporting = ref(false)
      const showAmbiguous = ref(true)
      const onlyIncomplete = ref(false)
      const combinationsTable = ref(null)

      const autoSwapColumns = () => {
        combinationsOptions.isCombinationsTableLoading = true

        try {
          combinationsTable.value?.refreshTabulator()

          // Analytics
          recordAnalytics(VM_AUTO_SWAP_COLUMN)
        }
        catch {
          combinationsOptions.isCombinationsTableLoading = false
        }
      }

      const handleCombinationsHeaderResize = ({ height } = {}) => {
        const combinationsDialogEl = document.querySelector('.combinations-dialog')
        const pxHeight = `${ height }px`

        combinationsDialogEl?.style?.setProperty('--combinations-header-height', pxHeight)
      }

      const buildVMTExcelName = (tableName = '') => {
        const { query } = route

        const pp = query?.pp ? atob(query.pp) : ''
        const pmName = query?.productModelBusinessName ? atob(query.productModelBusinessName) : ''

        const excelName = buildExcelName(tableName, pp, pmName)

        return excelName
      }

      const exportTableData = async (tableName = '') => {
        const { showCodeId, showName, splitCodeIdAndName } = resultsFilterExcel.value
        isTableDataExporting.value = true

        try {
          const excelFile = await exportVariantTreeTable({
            showCodeId,
            showName,
            splitCodeIdAndName,
            showAmbiguous: showAmbiguous.value,
            filters: filtersAsRequestParams(),
            sorters: sortersAsRequestParams(),
            aliases: aliasesAsRequestParams(),
            correlationId: props.correlationId
          })

          const vmExcelName = buildVMTExcelName(tableName)

          exportFile(vmExcelName, new Blob([excelFile]))

          // Analytics
          recordAnalytics(VM_COMB_TABLE_EXPORT)
        }
        finally {
          isTableDataExporting.value = false
        }
      }

      onMounted(() => {
        const { name, query } = route
        if (name === 'search' && query.searchString) {
          querySearchString.value = query.searchString
        }
      })

      return {
        handleCombinationsHeaderResize,
        exportTableData,
        showAmbiguous,
        onlyIncomplete,
        isTableDataExporting,
        querySearchString,
        autoSwapColumns,
        combinationsTable,
        buildVMTExcelName,
        combinationsOptions
      }
    }
  }
</script>

<style lang="scss">
  $combinations-dialog-flex-gap: 1.5rem;

  .combinations-dialog {
    --combinations-header-height: 40px;
  }

  .combinations-dialog .q-dialog__inner {
    padding: 0;

    .mi-card__section {
      display: flex;
      flex-direction: column;
      gap: $combinations-dialog-flex-gap;
      min-width: 1200px;
      height: 90%;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .combinations-dialog__table {
    height: calc(100% - var(--combinations-header-height) - #{$combinations-dialog-flex-gap});
  }
</style>
