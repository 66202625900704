<template>
  <mi-dialog
    :model-value="modelValue"
    :inner-loading="areValidationSettingsSaving"
    :persistent="areValidationSettingsSaving"
    class="validation-settings-dialog"
    no-route-dismiss
    @before-show="setSettingsDialogData"
    @update:model-value="emitUpdateModelValue"
  >
    <h5 class="q-mt-none q-mb-lg"> Validation settings </h5>

    <!-- Tabs -->
    <mi-tabs v-model="activeValidationType">
      <mi-tab
        v-for="(settingValue, settingName) in validationSettings"
        :key="settingName"
        :name="settingName"
        :label="settingValue.label"
      ></mi-tab>
    </mi-tabs>

    <mi-form
      class="q-pt-xs validation-settings-form"
      @validation-error="goToValidationErrorTab"
      @submit="saveValidationSettings"
    >
      <mi-tab-panels ref="validationTabPanels" v-model="activeValidationType" keep-alive>
        <!-- BOM panel -->
        <product-model-settings-tab
          name="bom"
          :settings="currentSettings.bom"
          :add-precondition="addPrecondition"
          :remove-precondition="removePrecondition"
          :invalid-choices="invalidChoices"
          @click:remove="removeValidationSetting"
        ></product-model-settings-tab>

        <!-- Not usable choices panel -->
        <product-model-settings-tab
          name="notUsableChoices"
          :settings="currentSettings.notUsableChoices"
          @click:remove="removeValidationSetting"
        ></product-model-settings-tab>
      </mi-tab-panels>

      <template v-if="activeValidationType === 'bom'">
        <!-- Add new setting button -->
        <div class="q-pt-xs">
          <mi-btn
            v-if="currentSettings.bom.length < validationSettingsLimit && activeValidationType === 'bom'"
            v-show="true"
            icon="plus-circle"
            icon-type
            icon-size="19px"
            flat
            @click="addValidationSetting"
          >
            Add new
          </mi-btn>
        </div>
      </template>
      <template v-else>
        <div>
          <mi-btn
            v-show="true"
            icon="plus-circle"
            icon-type
            icon-size="19px"
            flat
            @click="addValidationSetting"
          >
            Add new
          </mi-btn>
        </div>
      </template>

      <div class="flex q-pb-sm q-pt-sm justify-end">
        <mi-btn v-show="canCreate" color="accent" type="submit"> Apply </mi-btn>
      </div>
    </mi-form>
  </mi-dialog>
</template>

<script>
  import cloneDeep from 'lodash.clonedeep'
  import { createNamespacedHelpers } from 'vuex'

  import { saveProductModelSettings } from '@/api'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { PROD_MODEL_SAVED_VALIDATION } from '@/utils/analytics/constants'

  import { canAccess } from '@/utils/accessCheck'
  import ProductModelSettingsTab from './ProductModelSettingsTab.vue'

  const { mapGetters } = createNamespacedHelpers('product-model')

  export default {
    name: 'ProductModelSettings',
    components: { ProductModelSettingsTab },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      validationSettings: {
        type: Object,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      areValidationSettingsSaving: false,
      activeValidationType: '',
      currentSettings: {},
      canCreate: true,
      validationSettingsLimit: 10,
      shouldDisplay: true,
      invalidChoices: new Set()
    }),
    computed: {
      ...mapGetters(['selectedProductModel'])
    },
    created() {
      this.canCreate = (this.activeValidationType === 'bom')
        ? canAccess('CREATE_VALIDATION_SETTINGS')
        : canAccess('CREATE_NOT_USABLE_CHOICES_SETTINGS')
    },
    methods: {
      async addPrecondition(conditions, fieldIndex) {
        this.currentSettings[this.activeValidationType][fieldIndex].preCondition = conditions
      },
      async removePrecondition(choice, fieldIndex) {
        this.currentSettings[this.activeValidationType][fieldIndex].preCondition.splice(choice.index, 1)
      },
      async saveValidationSettings() {
        const params = {
          encodedBusinessName: this.selectedProductModel.encodedBusinessName,
          bomSettings: this.currentSettings.bom,
          invalidChoicesSettings: this.currentSettings.notUsableChoices
        }

        // Make sure range 'to' value, equals 'from' value
        this.planningPeriodCopyFrom(params.bomSettings)
        this.planningPeriodCopyFrom(params.invalidChoicesSettings)

        this.areValidationSettingsSaving = true

        try {
          await saveProductModelSettings(params)

          this.emitUpdateModelValue()

          notify({
            title: 'Saved',
            content: `Settings for "${ this.selectedProductModel.businessName }" product model were successfully saved`,
            type: 'positive'
          })

          this.invalidChoices = new Set()

          // Analytics
          recordAnalytics(
            PROD_MODEL_SAVED_VALIDATION,
            {
              productModelBusinessName: this.selectedProductModel.businessName
            }
          )
        }
        catch (e) {
          this.invalidChoices = new Set(
            e.response.data.bomSettings.flatMap(setting => setting.preCondition.invalidChoices.map(item => item.id2))
          )
        }
        finally {
          this.areValidationSettingsSaving = false
        }
      },
      planningPeriodCopyFrom(settings) {
        settings.forEach(item => {
          item.checkRange.to = item.checkRange.from
        })
      },
      addValidationSetting() {
        const emptySetting = {
          name: '',
          checkRange: { from: '', to: '' }
        }

        this.currentSettings[this.activeValidationType]?.push(emptySetting)
      },
      removeValidationSetting(settingIdx) {
        this.currentSettings[this.activeValidationType]?.splice(settingIdx, 1)
      },
      setSettingsDialogData() {
        [this.activeValidationType] = Object.keys(this.validationSettings)

        Object.keys(this.validationSettings).forEach(key => {
          this.currentSettings[key] = cloneDeep(this.validationSettings[key].items)
        })
      },
      goToValidationErrorTab(ref = {}) {
        if (ref.name in this.currentSettings) {
          this.$refs.validationTabPanels?.goTo(ref.name)
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .validation-settings-dialog .q-dialog__inner > .mi-card {
    min-width: 900px;
  }

  .validations-scroll {
    height: 200px;
  }

  .validation-settings-item {
    margin-top: 2px;

    .validation-row-center {
      margin-top: 24px;
    }

    ::v-deep(.mi-btn) {
      opacity: 0;
      pointer-events: none;
      font-size: .85rem;
    }

    &:hover ::v-deep(.mi-btn) {
      opacity: 1;
      pointer-events: auto;
    }
  }
</style>
