<template>
  <div class="row q-py-lg full-height">
    <div class="col-7 column full-height offset-1 no-wrap">
      <!-- Header -->
      <div class="flex no-wrap q-py-md items-center">
        <h5 class="q-my-none"> ALL USERS </h5>
        <span class="q-ml-md text-subtitle2 text-weight-regular"> {{ users.length }} USER(S) FOUND </span>

        <!-- Search field -->
        <mi-text-field
          v-model="userSearchString"
          class="user-management-search-field q-ml-auto"
          placeholder="Search for a username"
          clearable
          hide-bottom-space
          outlined
        >
          <template #prepend>
            <mi-icon name="search"></mi-icon>
          </template>
        </mi-text-field>
      </div>

      <!-- Table -->
      <div class="user-management-table-wrapper overflow-hidden-y">
        <mi-table
          loading-label="Getting users. Please wait ..."
          no-data-label="No users found"
          :columns="columns"
          :rows="users"
          hide-pagination
          striped
          :filter="userSearchString"
          :loading="areUsersLoading"
          class="user-management-table full-height"
          sticky-header
        >
          <template #body="{ key, row }">
            <mi-tr :key="key" no-hover>
              <mi-td> {{ row.username }} </mi-td>
              <mi-td> {{ row.department }} </mi-td>
              <mi-td> {{ row.role.description }} </mi-td>

              <mi-td>
                <div class="flex items-center no-wrap">
                  {{ formatLastAccessedAt(row.lastAccessedAt) }}
                  <mi-btn class="text-weight-regular q-ml-auto" dense flat @click="openEditUserDialog(row)">
                    Edit
                  </mi-btn>
                  <mi-btn class="text-weight-regular q-ml-sm" dense flat @click="openDeleteUserDialog(row)">
                    Delete
                  </mi-btn>
                </div>
              </mi-td>
            </mi-tr>
          </template>
        </mi-table>
      </div>
    </div>

    <!-- Edit user role dialog -->
    <edit-dialog
      v-model="isEditUserDialogShown"
      :user="selectedUser"
      @save:user="handleUserEvent"
    ></edit-dialog>

    <!-- Delete user dialog -->
    <delete-dialog
      v-model="isDeleteUserDialogShown"
      :user="selectedUser"
      @delete:user="handleUserEvent"
    ></delete-dialog>
  </div>
</template>

<script>
  import { getUsers } from '@/api'
  import DeleteDialog from '@/components/user-management-dialogs/UserManagementDeleteUserDialog.vue'
  import EditDialog from '@/components/user-management-dialogs/UserManagementEditUserDialog.vue'
  import formatDate from '@/utils/formatDate'

  export default {
    name: 'UserManagement',
    components: { DeleteDialog, EditDialog },
    data: () => ({
      userSearchString: '',
      areUsersLoading: false,
      selectedUser: {},
      users: [],
      isDeleteUserDialogShown: false,
      isEditUserDialogShown: false,
      columns: [
        {
          name: 'username',
          label: 'Username',
          align: 'left',
          field: 'username',
          sortable: true
        },
        {
          name: 'department',
          label: 'Department',
          align: 'left',
          field: 'department',
          sortable: true
        },
        {
          name: 'role',
          label: 'Role Permission',
          align: 'left',
          field: row => row.role?.description || '',
          sortable: true
        },
        {
          name: 'lastAccessedAt',
          label: 'Last Access',
          align: 'left',
          field: 'lastAccessedAt',
          sortDirection: 'ASC',
          sortable: true
        }
      ]
    }),
    mounted() {
      this.getUsersList()
    },
    methods: {
      async getUsersList() {
        this.areUsersLoading = true

        try {
          this.users = await getUsers()
        }
        finally {
          this.areUsersLoading = false
        }
      },

      formatLastAccessedAt(lastAccessedAt) {
        return lastAccessedAt ? formatDate(lastAccessedAt) : ''
      },
      openEditUserDialog({ id, username, role } = {}) {
        this.selectedUser = { id, username, role }
        this.isEditUserDialogShown = true
      },
      openDeleteUserDialog({ id, username, role } = {}) {
        this.selectedUser = { id, username, role }
        this.isDeleteUserDialogShown = true
      },
      handleUserEvent() {
        this.selectedUser = {}
        this.getUsersList()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-management-search-field {
    min-width: 300px;
  }

  .user-management-table-wrapper {
    flex-grow: 1;
  }

  .user-management-table ::v-deep(.mi-btn) {
    text-decoration: underline;
    // stylelint-disable declaration-no-important
    color: $mi-anthracite-500 !important;
  }

  .user-management-table ::v-deep(.q-table) {
    table-layout: fixed;
  }
</style>
