<template>
  <div id="inspector">
    <inspector-container></inspector-container>
  </div>
</template>

<script>
  import InspectorContainer from '@/components/inspector/InspectorContainer.vue'

  export default {
    name: 'Inspector',
    components: { InspectorContainer }
  }
</script>
