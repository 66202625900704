<template>
  <mi-form @submit="emitSubmitEvent">
    <div v-if="configurationType === CONFIGURATION_TYPES.IMPORT_FILE.name" class="row">
      <div class="col q-mb-xs">
        <!-- C4S File -->
        <mi-file
          v-model="file"
          :rules="[required]"
          accept="application/json"
          label="C4S File"
          clearable
          outlined
        >
          <template #prepend>
            <mi-icon name="file-upload"></mi-icon>
          </template>
        </mi-file>
      </div>
    </div>

    <div class="row q-gutter-sm q-mb-xs">
      <div class="col">
        <!-- Name -->
        <mi-text-field
          v-model="configuration.name"
          :rules="[maxLength(60), required]"
          label="Name"
          placeholder="e.g. T12345"
          outlined
          :disable="disableUnauthorizedItem"
        ></mi-text-field>
      </div>

      <div class="col">
        <!-- Project -->
        <mi-text-field
          v-model="configuration.project"
          :rules="[maxLength(50), required]"
          label="Project"
          placeholder="e.g. AA 11 BBB"
          outlined
          :disable="disableUnauthorizedItem"
        ></mi-text-field>
      </div>
    </div>

    <div class="row q-gutter-sm q-mb-xs">
      <div class="col">
        <!-- Product model -->
        <mi-select
          v-model="configuration.productModel"
          :display-value="configuration.productModel?.businessName || 'Select Product Model'"
          :options="productModels"
          :rules="[required]"
          :disable="configuration.id != null"
          label="Product Model"
          option-label="businessName"
          options-selected-class="text-accent"
          outlined
        ></mi-select>
      </div>

      <div class="col-4">
        <!-- Planning period -->
        <mi-text-field
          v-model="configuration.planningPeriodRange.from"
          :rules="planningPeriodRules"
          label="Planning Period"
          mask="######"
          placeholder="e.g. 202101"
          prefix="PP"
          outlined
          :disable="disableUnauthorizedItem"
        ></mi-text-field>
      </div>
    </div>

    <div v-if="configurationType === CONFIGURATION_TYPES.IMPORT_FILE.name" class="row">
      <div class="col">
        <!-- Windchill Id -->
        <mi-text-field
          v-model="configuration.windChillData.variantSpecNumber"
          :rules="[exactLengths(0,5)]"
          mask="#####"
          label="Windchill Id (optional)"
          placeholder="e.g. 00001"
          outlined
          :disable="disableUnauthorizedItem"
        ></mi-text-field>
      </div>
    </div>

    <div class="user-permission-wrapper col q-gutter-sm">
      <div class="select-permission">
        <mi-select
          v-model="userPermissions"
          :options="USER_PERMISSION_OPTIONS.OPTIONS"
          label="Editing permissions"
          option-label="label"
          options-selected-class="text-accent"
          outlined
          :disable="disableUnauthorizedItem"
        >
        </mi-select>
      </div>
      <div v-if="userPermissions === USER_PERMISSION_OPTIONS.SELECTED_USERS">
        <mi-select
          ref="chips"
          :model-value="selectedUsers"
          label="Editing users"
          outlined
          use-input
          hint="Besides creator, add more than one user ID separated by “;” to edit"
          multiple
          :error="selectError.invalid"
          :error-message="selectError.message"
          hide-dropdown-icon
          input-debounce="0"
          new-value-mode="add-unique"
          data-cy="user-list"
          :disable="disableUnauthorizedItem"
          @new-value="createValue"
        >
          <template #prepend>
            <q-chip
              v-for="(user, index) in userList"
              :key="index"
              class="chips q-mr-xs"
              removable
              dense
              :disable="index === 0"
              icon-remove="close"
              @remove="userList.splice(index, 1)"
            >
              {{ user }}
            </q-chip>
          </template>
        </mi-select>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <!-- Description -->
        <mi-text-field
          v-model="configuration.description"
          :rules="[maxLength(200)]"
          label="Description"
          placeholder="e.g. Configuration purpose"
          outlined
          :disable="disableUnauthorizedItem"
        ></mi-text-field>
      </div>
    </div>

    <slot name="actions">
      <!-- Stepper navigation -->
      <mi-stepper-navigation class="q-pt-sm">
        <mi-btn flat @click="$emit('click:back')"> Back </mi-btn>
        <mi-btn data-cy="continue-btn" class="q-ml-sm" type="submit"> Continue </mi-btn>
      </mi-stepper-navigation>
    </slot>
  </mi-form>
</template>

<script>
  import cloneDeep from 'lodash.clonedeep'
  import { createNamespacedHelpers, mapState as mapStateUser } from 'vuex'

  import { exactLength, exactLengths, maxLength, regex, required } from '@/utils/validators'
  import { addPrefixToPlanningPeriod, removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'
  import { CONFIGURATION_TYPES, EMPTY_CONFIGURATION_TEMPLATE, USER_PERMISSION_OPTIONS } from '@/constants'
  import { containsSpecialChars } from '@/utils/validators/common'

  const { mapState } = createNamespacedHelpers('product-model')
  const { mapState: mapStatePac, mapGetters } = createNamespacedHelpers('pac')

  export default {
    name: 'NewConfigurationDialogCreateForm',
    props: {
      configurationType: {
        type: String,
        required: false,
        default: CONFIGURATION_TYPES.EMPTY.name
      },
      isSaveAsNew: {
        type: Boolean,
        default: false
      },
      initialConfiguration: {
        type: Object,
        required: false,
        default: () => EMPTY_CONFIGURATION_TEMPLATE
      },
      errorMessageUnauthorized: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: ['click:back', 'submit', 'new-value'],
    data: () => ({
      CONFIGURATION_TYPES,
      USER_PERMISSION_OPTIONS,
      userPermissions: '',
      userList: [],
      selectedUsers: [],
      selectError: {
        invalid: false,
        message: '',
        maxComponentsLimit: 20,
        rules: {
          separator: 'Component IDs must be separated by ;',
          maxComponents: 'You can only validate up to 20 components at a time'
        }
      },
      exactLength,
      exactLengths,
      maxLength,
      required,
      configuration: {},
      file: null,
      planningPeriodRules: [
        required,
        exactLength(6),
        regex(
          /^\d{4}(0[1-9]|[1-2]\d|3[0-6])$/,
          'Field must match the following format YYYYPP (i.e., YYYY=Year, PP=01-36)'
        )
      ]
    }),
    computed: {
      ...mapState(['productModels']),
      ...mapGetters(['isAuthorizedToEditCurrentConfiguration']),
      ...mapStatePac(['currentConfiguration']),
      ...mapStateUser(['user']),
      disableUnauthorizedItem() {
        return !this.isAuthorizedToEditCurrentConfiguration && !!this.initialConfiguration.name && !this.isSaveAsNew
      }
    },
    watch: {
      configurationType() {
        this.file = null
      },
      errorMessageUnauthorized(newVal) {
        this.selectError.invalid = true
        this.selectError.message = newVal
      }
    },
    created() {
      const { from: fromPPWithPrefix } = this.initialConfiguration.planningPeriodRange || {}

      this.configuration = cloneDeep(this.initialConfiguration)
      this.configuration.planningPeriodRange.from = removePrefixFromPlanningPeriod(fromPPWithPrefix)
      this.editPermissionsSelectedUsers()
    },
    methods: {
      emitSubmitEvent() {
        const { from } = this.configuration.planningPeriodRange || {}
        const fromPPWithPrefix = addPrefixToPlanningPeriod(from)

        const configuration = {
          ...this.configuration,
          authorizedUsers: this.userPermissions === USER_PERMISSION_OPTIONS.SELECTED_USERS ? this.userList : [],
          planningPeriodRange: {
            from: fromPPWithPrefix,
            to: fromPPWithPrefix
          }
        }

        this.$emit('submit', { configuration, file: this.file })
      },
      editPermissionsSelectedUsers() {
        const { authorizedUsers } = this.configuration
        const currentUser = this.user.username

        if (authorizedUsers?.length > 0 && !this.isSaveAsNew) {
          const authorizedList = authorizedUsers.filter(user => user !== currentUser)
          if (authorizedUsers.includes(currentUser)) {
            this.userList.push(currentUser)
          }
          this.userList.push(...authorizedList)
          this.userPermissions = USER_PERMISSION_OPTIONS.SELECTED_USERS
        }
        else {
          this.userPermissions = USER_PERMISSION_OPTIONS.EVERYONE
          this.userList.push(currentUser)
        }
      },
      createValue(val, done) {
        const userID = this.userList || []
        const valuesList = val.split(';')

        this.validateSelection(userID, val, valuesList)

        if (this.selectError.invalid) return

        valuesList.map(v => v.trim())
          .filter(v => v.length > 0 && v.length === 5)
          .forEach(v => userID.push(v))
        this.userList = [...new Set(userID)]
        this.$nextTick(() => {
          done?.([...this.userList].pop())
        })
      },
      validateSelection(components, selectedValue, values) {
        const hasSpecialChar = containsSpecialChars(selectedValue)
        const isLimitReached = this.isComponentLimitReached(components, values)

        this.selectError.invalid = hasSpecialChar || isLimitReached

        if (hasSpecialChar) {
          this.selectError.message = this.selectError.rules.separator
        }

        if (isLimitReached) {
          this.selectError.message = this.selectError.rules.separator
        }
      },
      isComponentLimitReached(components, values) {
        return components.length + values.length >= this.selectError.maxComponentsLimit
      }
    }
  }
</script>

<style lang="scss">
  .user-permission-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 0 24px;
  }

  .select-permission {
    width: 240px;
  }

  .chips-input {
    padding-bottom: 50px;
  }

  .q-chip {
    border: 2px solid $mi-anthracite-800;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    height: 24px;
    width: fit-content;
  }

  .q-chip__icon {
    // stylelint-disable declaration-no-important
    font-size: 7px !important;
    padding: 4px;
    background-color: $mi-anthracite-800;
    border-radius: 50%;
    color: $mi-white;
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
