// Helper to create rules for Quasar validation
export function createRule({ isValid = function () {}, errorMessage = '' } = {}) {
  return value => isValid(value) || errorMessage
}

// Helper to check if a string has special chars
export function containsSpecialChars(str) {
  const regex = /[!@#$%^&*()_+\-=[\]{}':"\\|,.<>/?]/
  return regex.test(str)
}
