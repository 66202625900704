<template>
  <mi-dialog
    key="inspector-explanation-dialog"
    :model-value="modelValue"
    class="inspector-explanation-dialog"
    no-backdrop-dismiss
    custom-design-dialog
    @hide="handleCloseDialog"
  >
    <div>
      <div class="inspector-explanation-header">
        <h3 class="q-my-none"> Learn more about the Inspection </h3>
      </div>
      <div class="inspector-explanation-content">
        <div class="inspector-explanation-content--section">
          <h6 class="inspector-explanation-content--content-title text-uppercase">
            What it does
          </h6>
          <h6 class="inspector-explanation-content--content-body">
            This feature lets you request CoRE a list of all valid component variants (KVs) from different combinations.
            You will get an excel file with all component variants listed from the combinations entered.
          </h6>
          <img
            src="@/assets/images/inspector/new-inspection-btn-info.svg"
            alt="inspector-btn-info"
          />
        </div>
        <div class="inspector-explanation-content--section">
          <h6 class="inspector-explanation-content--content-title text-uppercase">
            Setting it up
          </h6>
          <h6 class="inspector-explanation-content--content-body">
            Access the feature and then press “New inspection” button.
            A side bar will open for you to specify the product model, planning period and choices combinations.
            <strong>
              Please note:
            </strong>
            You can add up to 5 different combinations;
            You should repeat the choices when need in more than one combination.
          </h6>
          <img
            src="@/assets/images/inspector/select-choices-info.svg"
            alt="inspector-btn-info"
          />
        </div>
        <div class="inspector-explanation-content--section">
          <h6 class="inspector-explanation-content--content-title text-uppercase">
            Can I get a list for only a choice?
          </h6>
          <h6 class="inspector-explanation-content--content-body">
            Yes, you can add only one choice in the field and CoRE will return the valid KVs
            for that choice for the product model and planning period set.
          </h6>
          <img
            src="@/assets/images/inspector/spreadsheet-info.svg"
            alt="inspector-btn-info"
          />
        </div>
      </div>
      <div class="inspector-explanation-footer">
        <mi-btn v-close-popup color="accent"> Got it </mi-btn>
      </div>
    </div>
  </mi-dialog>
</template>

<script>
  import MiDialog from '@/packages/@mi-library/MiDialog/MiDialog.vue'

  export default {
    name: 'InspectorExplanationDialog',
    components: { MiDialog },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      handleCloseDialog: {
        type: Function,
        required: false,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss">
  .inspector-explanation-dialog .q-dialog__inner > .mi-card {
    // stylelint-disable declaration-no-important
    min-height: 700px;
    min-width: 958px;
    max-width: 958px;
    position: relative;
    box-shadow: 0 4px 8px 0 #0000003d !important;
  }

  .inspector-explanation-header {
    background-color: $mi-anthracite-50;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .inspector-explanation-content {
    width: 100%;
    padding: 40px 32px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    &--section {
      flex: 1;
      max-width: 33%;
      margin-right: 12px;
    }

    &--content {
      margin-top: 12px;
    }

    &--content-title {
      font-size: 18px;
      font-weight: 700;
      color: $mi-anthracite-800;
      margin: 0 0 1%;
      margin-bottom: 8px;
      display: flex;
      gap: 8px;
    }

    &--content-body {
      font-family: $mi-typography-font-family;
      margin: 0;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      height: 250px;
    }
  }

  .inspector-explanation-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px 24px;
    text-align: right;

    button {
      width: 86px;
    }
  }
</style>
