import { reactive } from 'vue'

const combinationsOptions = reactive({
  appliedAliasesIds: [],
  appliedFilters: [],
  appliedSorters: [],
  isCombinationsTableLoading: false,
  combinationsTableLoadingContent: ''
})

export const useCombinationsOptions = () => {
  const aliasesAsRequestParams = () => {
    const aliases = {}
    const aliasesLength = combinationsOptions.appliedAliasesIds.length

    for (let i = 0; i < aliasesLength; i++) {
      aliases[`aliases[${ i }][id]`] = combinationsOptions.appliedAliasesIds[i]
    }

    return aliases
  }

  const filtersAsRequestParams = () => {
    const filters = {}
    const appliedFiltersLength = combinationsOptions.appliedFilters.length

    for (let i = 0; i < appliedFiltersLength; i++) {
      const { field = '', value = '' } = combinationsOptions.appliedFilters[i]

      if (field && value) {
        filters[`filters[${ i }][field]`] = field
        filters[`filters[${ i }][type]`] = 'like'
        filters[`filters[${ i }][value]`] = value
      }
    }
    return filters
  }

  const sortersAsRequestParams = () => {
    const sorters = {}
    const appliedSortersLength = combinationsOptions.appliedSorters.length

    for (let i = 0; i < appliedSortersLength; i++) {
      const { column = '', dir = '' } = combinationsOptions.appliedSorters[i]

      if (column && dir) {
        sorters[`sorters[${ i }][field]`] = column
        sorters[`sorters[${ i }][dir]`] = dir
      }
    }

    return sorters
  }

  const resetCombinationsOptions = () => {
    combinationsOptions.appliedFilters = []
    combinationsOptions.appliedSorters = []
    combinationsOptions.appliedAliasesIds = []
    combinationsOptions.isCombinationsTableLoading = false
    combinationsOptions.combinationsTableLoadingContent = ''
  }

  return {
    combinationsOptions,
    aliasesAsRequestParams,
    filtersAsRequestParams,
    sortersAsRequestParams,
    resetCombinationsOptions
  }
}
